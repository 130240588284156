$accentColor: #bada55;
$fontSans: "Lato", "Helvetica Neue", "Helvetica", "Arial", "Verdana", sans-serif;
$fontSerif: "Cambria", "Cochin", "Georgia", "Times", "Times New Roman", serif;
$mediumLimit: 745px;
$maxLimit: 1210px;

html, body {
	margin: 0px;
	background-color: #f2f2f2;
	font-family: $fontSans;
}
* {
	box-sizing: border-box;
}

a {
	color: $accentColor;
	text-decoration: none;
}

@mixin shadow {
	box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.08);
}

@mixin button {
	background-color: #ffffff;
	padding: 15px 20px;
	border-radius: 30px;
	@include shadow;
	text-align: center;
}

.btn {
	@include button;
	display: inline-block;
	color: #ffffff !important;
	background-color: $accentColor;
	min-width: 125px;
}

.row {
	display: flex;
	justify-content: space-between;

	> * {
		flex-grow: 1;
		flex-basis: 0px;
	}
}

*:not(pre) > code {
	padding: 2px 4px;
	font-size: 90%;
	font-family: "Cascadia Code", "Cascadia Mono", "Courier New", Courier, monospace;
	color: #666666;
	background-color: #f9f2f4;
	border-radius: 4px;
}

main.index {
	> header {
		padding: 180px 0px;
		background-image: url("../assets/Renar.jpg");
		background-size: cover;
		background-position: center;
		color: #ffffff;
		text-align: center;

		h1 {
			font-family: $fontSerif;
			font-style: italic;
			font-size: 50px;
			font-weight: 600;
			margin: 0px;
		}
		p {
			font-size: 20px;
			font-weight: 900;
			letter-spacing: 5px;
			margin-top: 15px;
		}
	}

	> section {
		margin: 50px;
		display: flex;
		column-gap: 50px;
		flex-wrap: wrap;
		justify-content: space-between;
		@media screen and (min-width: $mediumLimit) {
			margin: 50px 80px;
		}
		@media screen and (min-width: $maxLimit) {
			margin: 50px 220px;
		}

		> article {
			background-color: #ffffff;
			border-radius: 3px;
			@include shadow;
			padding: 20px;
			margin-bottom: 25px;
			width: 100%;
			@media screen and (min-width: $mediumLimit) {
				max-width: calc(calc(100% - 50px) / 2);
			}
			@media screen and (min-width: $maxLimit) {
				max-width: calc(calc(100% - 100px) / 3);
			}

			.image {
				width: calc(100% + 40px);
				padding-top: 100%;
				margin: -20px;
				margin-bottom: 1em;
				display: block;
				position: relative;
				background-size: cover;
				background-position: center;
				border-top-left-radius: 3px;
				border-top-right-radius: 3px;

				h5 {
					position: absolute;
					margin: 0px;
					color: #ffffff;
					left: 30px;
					bottom: 10px;
				}
			}

			h2 {
				font-family: $fontSerif;
				font-size: 25px;
				font-weight: 700;
				font-style: italic;
				margin: 10px 0px 5px 0px;
				> a {
					color: #4a4849;
				}
			}
			h5 {
				color: #afafaf;
				font-size: 14px;
				letter-spacing: 1px;
				font-weight: 900;
				margin: 0px 0px 5px 0px;
			}

			> .body > a {
				color: #949494;
				font-size: 15px;
				line-height: 1.7;
			}
		}
	}

	> nav {
		margin: 0px 20px;
		margin-bottom: 35px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 25px;

		a {
			width: 100%;
			@include button;

			&.older {
				flex-direction: row-reverse;
			}
		}

		@media screen and (min-width: $mediumLimit) {
			margin: 0px 80px;
			margin-bottom: 35px;

			a {
				width: calc((100% - 25px) / 2);
				max-width: 300px;
			}
		}
		@media screen and (min-width: $maxLimit) {
			margin: 0px 220px;
			margin-bottom: 35px;
		}
	}
}

main.post {
	display: grid;
	grid-template-columns: Max(20px, 5%) 1fr Max(20px, 5%);
	grid-template-rows: 70px auto auto auto;
	grid-template-areas:
		"header header header"
		". article ."
		". nav ."
		". aside .";
	@media screen and (min-width: $mediumLimit) {
		grid-template-columns: 5% 3fr 1fr 5%;
		grid-template-rows: 70px auto auto auto;
		grid-template-areas:
			"header header header header"
			". article aside ."
			". nav aside .";
		gap: 25px;
	}
	@media screen and (min-width: $maxLimit) {
		grid-template-columns: 12.5% 3fr 1fr 12.5%;
	}

	> header {
		grid-area: header;
		padding: 0px Max(20px, 5%);
		background-color: #ffffff;
		@include shadow;
		@media screen and (min-width: $mediumLimit) {
			padding: 0px 5%;
		}
		@media screen and (min-width: $maxLimit) {
			padding: 0px 12.5%;
		}

		> h1 {
			padding: 15px;
			margin: 0px;
			line-height: 40px;
			font-size: 20px;
			font-style: italic;
			font-family: $fontSerif;
			font-weight: 600;
			> a {
				color: #333333;
			}
		}
	}

	> article {
		grid-area: article;
		margin: 20px 0px;
		padding: 20px 20px 40px 20px;
		background-color: #ffffff;
		border-radius: 3px;
		@include shadow;
		@media screen and (min-width: $mediumLimit) {
			padding: 20px 50px 40px 50px;
		}

		> header {
			> h1 {
				color: #2f2f2f;
				margin-bottom: 14px;
				font-size: 36px;
				font-style: italic;
				font-family: $fontSerif;
				font-weight: 900;
			}
			time {
				display: inline;
				color: #afafaf;
				font-size: 14px;
				letter-spacing: 1px;
				font-weight: 900;
			}
		}

		> main {
			margin-top: 40px;
			line-height: 1.7;
			font-size: 16px;

			h1, h2, h3, h4, h5, h6 {
				font-style: italic;
				font-family: $fontSerif;
			}

			p {
				margin-bottom: 1.5em;
			}

			img {
				max-width: 100%;
			}
		}
	}

	> nav {
		grid-area: nav;
		@media screen and (min-width: $mediumLimit) {
			display: flex;
			justify-content: space-between;
			gap: 65px;
		}

		> a {
			display: block;
			margin-bottom: 35px;
			padding: 15px 25px;
			background-color: #ffffff;
			border-radius: 3px;
			@include shadow;
			flex-basis: 0;
			flex-grow: 1;
			text-align: center;
			line-height: 1.5;
			font-weight: 600;

			> span:nth-of-type(2) {
				color: #6b6b6b;
			}
		}
	}

	> aside {
		grid-area: aside;
		align-self: start;
		margin-bottom: 35px;
		padding: 15px 25px;
		background-color: #ffffff;
		border-radius: 3px;
		@include shadow;
		@media screen and (min-width: $mediumLimit) {
			margin-top: 20px;
		}

		> h4 {
			color: #2f2f2f;
			font-weight: 900;
			margin-top: 10px;
			margin-bottom: 20px;
		}
		> ul {
			margin: 0px;
			padding: 0px;
			list-style: none;
			> li {
				margin: 10px;
				> a {
					color: #6b6b6b;
				}
			}
		}
	}
}

body > footer {
	background-color: #212324;
	color: #7f7f7f;
	text-align: center;

	p {
		padding: 30px 50px;
		margin: 0px;

		a:not(:hover) {
			filter: brightness(0.5);
		}
	}

	hr {
		border-color: #7f7f7f;
		border-bottom-width: 0px;
	}
}
